import React, { useEffect, useState } from "react";
import bglogo from "../LoginAssets/bg_login.png";
import logo from "../Components/Dashboard/assets/marshall2.png";

const Maintenance = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("October 28, 2024 00:00:00").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div
      style={{
        display: "grid",
        backgroundImage: `url(${bglogo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: 'no-repeat',
        justifyContent: "center",
        alignContent: "center",
        width: '100vw',
        minHeight: '100vh', // Mengisi minimal tinggi viewport
        margin: 0,
        padding: 0,
      }}
    >
      <div
        style={{
          display: "grid",
          width: "500px",
          height: "300px",
          backgroundColor: "whitesmoke",
          borderRadius: "20px",
          justifyContent: "center",
          alignContent: "center",
          boxShadow: "0 7px 50px black"
        }}
      >
        <div
          style={{
            display: "grid",
            width: "400px",
            height: "150px",
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo Image"
            style={{ width: "200px", marginBottom: "20px", marginLeft: "70px" }}
          ></img>
          <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
            Under-Maintenance
          </span>
          
          <div style={{marginBottom:"20px", fontSize:"13px"}}>
            <span style={{fontWeight:"bold"}}>Countdown to October 28, 2024</span>
            <p>
              {timeLeft.days} days, {timeLeft.hours} hours, {timeLeft.minutes}{" "}
              minutes, {timeLeft.seconds} seconds
            </p>
          </div>

          <span style={{ fontSize: "16px" }}>
            Mohon maaf atas ketidaknyamanan Anda.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
